(function ($) {
    $(function () {
        $('.hotline-button')
            .clone()
            .removeClass('hotline-button--desktop')
            .addClass('hotline-button--mobile')
            .appendTo('.hero__image')
            .on('click', function () {
                $(this).toggleClass('hotline-button--active');
            });
    });
})(jQuery);
