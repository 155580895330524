(function ($) {
    $(function () {
        var trackingEventNameClick = 'click';
        var trackingEventNameView = 'view';

        $('.hero').each(function () {
            var hero = $(this);
            var items = hero.find('.hero__item');
            if (items.length === 1) {
                trackHeroItem(items, trackingEventNameView, '1/1');
            } else if (items.length > 1) {
                hero
                    .on('afterChange', function (event, slick, currentSlide, nextSlide) {
                        trackCurrentSlide(slick, trackingEventNameView);
                    })
                    .on('init', function (event, slick) {
                        trackCurrentSlide(slick, trackingEventNameView);
                    })
                    .slick({ arrows: false, dots: true, dotsClass: 'hero__pager', autoplay: true, autoplaySpeed: 5000 });
            }
        });
        $('.hero__cta').on('click', function (ev) {
            var $this = $(this);
            var parentHero = $this.closest('.hero');
            var isInSlider = parentHero.hasClass('slick-slider');
            if (isInSlider)
                trackCurrentSlide(parentHero.slick('getSlick'), trackingEventNameClick);
            else
                trackHeroItem($this.closest('.hero__item'), trackingEventNameClick, '1/1');
        });

        function trackCurrentSlide(slick, event) {
            var currentSlide = slick.currentSlide;
            var position = currentSlide + 1 + "/" + slick.slideCount;
            var slideItem = slick.$slides.get(currentSlide);
            if (slideItem) {
                var heroItem = jQuery(slideItem.innerHTML).find(".hero__item");
                trackHeroItem(heroItem, event, position);
            }
        }

        function trackHeroItem(heroItem, event, position) {
            var trackingEnabled = heroItem.data('tracking-enabled');
            if (trackingEnabled) {
                var trackingObj = getHeroItemTrackingData(heroItem);
                trackingObj.creative_slot = position;
                trackPromotion(trackingObj, event); //from _trackingPromotion.js
            }
        }

        function getHeroItemTrackingData(heroItem) {
            return {
                promotion_id: heroItem.data('tracking-id'), // Name or ID is required.
                promotion_name: heroItem.data('tracking-name').replace(/'/g, "\\'"), // Name des Banners
                creative_name: heroItem.data('tracking-creative').replace(/'/g, "\\'"),// Verallgemeinerung Inhalt (Aktion, Gutschein, Themenpromotion, Wettbewerb etc.)
                location_id: 'hero' // Art der Ausspielung, Bannergrösse optional (Teaser, Slider,MainTeaser etc.)
            }
            //note: not used anymore in the new 2021 version:
            //trackingObj['bereich'] = heroItem.data('tracking-area').replace(/'/g, "\\'");
            //trackingObj['rubrik'] = heroItem.data('tracking-category').replace(/'/g, "\\'");
        }

    });
})(jQuery);
