(function ($) {
    $(function () {
        $('.image-bullets').each(function (index, item) {
            var $item = $(item);
            var itemCount = $item.children().length;

            $item
                //wire-up events first, or else 'init' will not be triggered, when Slick is set up
                .on('init breakpoint', function (event, slick) {
                    var newImageHeight = slick.$slides.find('.image-bullets__image').first().outerHeight() + 'px';
                    slick.$prevArrow.css('height', newImageHeight);
                    slick.$nextArrow.css('height', newImageHeight);
                })
                .on('setPosition', function (event, slick) {

                    var titleHeight = 0;
                    slick.$slides.find('.image-bullets__title')
                        .css('height', 'auto')
                        .each(function (index, title) {
                            var height = $(title).outerHeight();
                            if (height > titleHeight)
                                titleHeight = height;
                        })
                        .css('height', titleHeight + 'px');

                    slick.$slides.children().css('height', slick.$slideTrack.outerHeight() + 'px');
                })
                .slick({
                    arrows: true,
                    prevArrow: '<button type="button" class="image-bullets__button image-bullets__button--previous"><i class="fas fa-chevron-left"></i></button>',
                    nextArrow: '<button type="button" class="image-bullets__button image-bullets__button--next"><i class="fas fa-chevron-right"></i></button>',
                    dots: true,
                    dotsClass: 'image-bullets__pager',
                    mobileFirst: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    responsive: [
                        {
                            //for tablet landscape and up, we don't want a slider
                            breakpoint: CONFIG.breakpoints.tabletPortraitMinWidth,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            //for tablet landscape and up, we want to show max 4 items at once
                            breakpoint: CONFIG.breakpoints.tabletLandscapeMinWidth,
                            settings: {
                                slidesToShow: Math.min(itemCount, 4),
                                slidesToScroll: Math.min(itemCount, 4)
                            }
                        }
                    ]
                });
        });
    });
})(jQuery);
