(function ($) {
    $(function () {
        $('.js-top-news-close').on('click', function () {
            $(this).closest('.top-news').slideUp('fast');

            var expires = new Date();
            expires.setTime(expires.getTime() + (1/*1 day*/ * 24 * 60 * 60 * 1000));
            document.cookie = 'hide-top-news=1;expires=' + expires.toUTCString() + ';path=/';
        });
    });
})(jQuery);