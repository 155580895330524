(function ($) {
    var emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    function validateEmail($inputField) {
        if (!emailRegex.test($inputField.val().toLowerCase())) {
            setError($inputField, $inputField.data('val-email'));
            return false;
        }

        return true;
    }

    function validateRequired($inputField) {
        if ($inputField.val().trim() == '') {
            setError($inputField, $inputField.data('val-required'));
            return false;
        }

        return true;
    }

    function setError($inputField, errorMessage) {
        var $wrapper = $inputField.closest('.migrol-form__input-wrapper');
        if (!$wrapper.hasClass('migrol-form__input-wrapper--error'))
            $wrapper.addClass('migrol-form__input-wrapper--error');
        if (errorMessage && errorMessage != '')
            $wrapper.find('.migrol-form__error-message').text(errorMessage);
    }

    function populateFieldsFromLocalStorage() {
        $('#blog-comment-author-name').val(window.localStorage.getItem('blog-comment-author-name'));
        $('#blog-comment-author-email').val(window.localStorage.getItem('blog-comment-author-email'));
    }

    $(function () {

        var $commentFormTemplate = $('#js-comment-form-template');
        if ($commentFormTemplate.length == 0)
            return; //comments are disabled

        var commentFormHtml = $commentFormTemplate.html();
        var $mainCommentFormContainer = $('#js-main-comment-form-container');
        var $currentContainer = $mainCommentFormContainer;

        $mainCommentFormContainer.html(commentFormHtml);
        populateFieldsFromLocalStorage();

        //Reply-Link click
        $('#js-blog-comment-container').on('click', '.js-button-blog-comment-reply', function () {
            var $button = $(this);
            var $container = $button.parent();

            //remove the main comment form
            $currentContainer.empty();

            //add a new comment form
            $currentContainer = $container.find('.js-comment-form-html');
            $currentContainer.html(commentFormHtml);
            populateFieldsFromLocalStorage();

            //hide answer-button
            $container.find('.js-button-blog-comment-reply').hide();

            var $form = $container.find('form');

            //set parent comment id
            $form.find('input[name=parentId]').val($container.data('comment-id'));
        });

        //Cancel Button click
        $('#js-blog-comment-container').on('click', '.js-button-blog-comment-cancel', function () {
            var $button = $(this);
            var $container = $button.closest('.js-comment-form-container');

            //remove form
            $currentContainer.empty();

            //show answer-button again
            $container.find('.js-button-blog-comment-reply').show();

            //add main comment form again
            $currentContainer = $mainCommentFormContainer;
            $currentContainer.html(commentFormHtml);
            populateFieldsFromLocalStorage();
        });

        //Submit button click
        $('.blog-comments').on('click', '.js-button-blog-comment-submit', function () {
            var $button = $(this);
            var $form = $button.closest('form');

            //validation logic
            $('.blog-comments .migrol-form__input-wrapper--error').removeClass('migrol-form__input-wrapper--error'); //remove all error classes
            $('.blog-comments .migrol-form__error-message').empty(); //remove all error messages

            var $textField = $('#blog-comment-text');
            var $nameField = $('#blog-comment-author-name');
            var $emailField = $('#blog-comment-author-email');
            var $tokenField = $('#blog-comment-token');

            var isValid = true;
            isValid = validateRequired($textField) && isValid;
            isValid = validateRequired($nameField) && isValid;
            isValid = validateRequired($emailField) && isValid;
            if (isValid)
                isValid = validateEmail($emailField) && isValid;

            if (!isValid)
                return;

            //save name and email to local storage
            window.localStorage.setItem('blog-comment-author-name', $nameField.val());
            window.localStorage.setItem('blog-comment-author-email', $emailField.val());

            //show loading indicator
            $currentContainer.html('<div class="blog-comments__success-message"><i class="fas fa-spinner fa-spin"></i></div>');

            //do the recaptcha things
            grecaptcha.execute(CONFIG.GoogleReCaptchaSiteKey, { action: 'blogcomment' })
                .then(function (token) {
                    //set token value from recaptcha
                    $tokenField.val(token);
                    console.log($tokenField, $tokenField.val(), token);

                    //submit
                    var postData = {};
                    $form.serializeArray().map(function (item) { postData[item['name']] = item['value']; }); //serialize form to a proper json object
                    postData.lang = CONFIG.language;

                    $.post('/migrolapi/blog/PostComment', postData)
                        .done(function () {
                            //show success message
                            $currentContainer.html('<div class="blog-comments__success-message">' + BLOGTEXTS.CommentSubmittedSuccess + '</div>');
                            $('.blog-comments .js-button-blog-comment-reply').show();

                            //reset main comment form
                            if ($mainCommentFormContainer.children().length == 0) {
                                $mainCommentFormContainer(commentFormHtml);
                                populateFieldsFromLocalStorage();
                            }
                        })
                        .fail(function () {
                            $currentContainer.html('<div class="blog-comments__error-message">' + BLOGTEXTS.CommentSubmittedError + '</div>');
                        });
                });
        });
    });
})(jQuery);