(function ($) {
    $(function () {
        $('a.zoom-image[href!=""] > img') //with this selector, we make sure, there is a a.zoom-image with an img as a direct child
            .parent() //now we actually want the parent
            .each(function (index, element) {
                //preload images, so the zoom effect works
                var img = new Image(),
                imgSrc = element.getAttribute("href");
                img.src = imgSrc;
            })
            .magnificPopup({
                type: 'image',
                mainClass: 'mfp-with-zoom',
                zoom: {
                    enabled: true,
                    duration: 300,
                    easing: 'ease-in-out'
                }
            });
    });
})(jQuery);