(function ($) {
    $(function () {
        $('.buy-energy__tab').on('click', function (ev) {
            ev.preventDefault();
            var $this = $(this);
            $this.siblings().removeClass('buy-energy__tab--active');
            $this.addClass('buy-energy__tab--active');
            $this.closest('.buy-energy').find('.buy-energy__tab-content--active').removeClass('buy-energy__tab-content--active');
            var tabToActivate = $this.closest('.buy-energy').find('.buy-energy__tab-content').eq($this.index());
            tabToActivate.addClass('buy-energy__tab-content--active');

            var productName = tabToActivate.find('.buy-energy__submit').data('product');
            var position = tabToActivate.closest('.buy-energy__content').children('.buy-energy__tab-content').index(tabToActivate) + 1;
            dataLayer.push({
                event: 'select_item',
                ecommerce: {
                    items: [{
                        item_name: productName, // Produktname in Shopsprache - Heizöl, Holzpellets, Diesel
                        item_brand: 'migrol', // Brand des Produktes - falls nicht vorhanden 'migrol'
                        item_category: productName, // z.B.: Heizöl, Holzpellets, Diesel
                        index: position // Position der Produkt auf der Seite (1, 2, 3)
                    }]
                }
            });
        });

        $('.buy-energy__submit').on('click', function (ev) {
            var $this = $(this);
            // remove all non-numeric characters from inputs:
            $this.siblings('input[type=text]').each(function () {
                var input = $(this);
                if (input.val()) {
                    input.val(input.val().replace(/\D/g, ''));
                }
            });
            var productName = $this.data('product');
            var closestContent = $this.closest('.buy-energy__tab-content');
            //var position = $this.closest('.buy-energy__content').children('.buy-energy__tab-content').index(closestContent) + 1;
            dataLayer.push({
                'event': 'formular',
                'formularTyp': 'Energie Kaufen Teaser',
                'formularName': productName
            });
            dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object. (WWW-2077)
            dataLayer.push({
                event: 'add_to_cart',
                ecommerce: {
                    items: [{
                        item_name: productName, // Produktname in Shopsprache - Heizöl, Holzpellets, Diesel
                        item_brand: 'migrol', // Brand des Produktes - falls nicht vorhanden 'migrol'
                        item_category: productName, // z.B.: Heizöl, Holzpellets, Diesel
                        quantity: closestContent.find('input[name ="m"]').val() ? parseInt(closestContent.find('input[name ="m"]').val()) : null // Menge
                    }]
                }
            });

        });
    });
})(jQuery);