(function ($) {
    $(function () {
        $('.js-teaser-carousel__items').slick({
            arrows: false,
            dots: true,
            dotsClass: 'teaser-carousel__pager',
            autoplay: true,
            autoplaySpeed: 5000
        });
    });
})(jQuery);