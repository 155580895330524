(function ($) {
    $(function () {

        //make the whole teaser clickable
        if (!CONFIG.inExpEditor)
            $('.blog-post-teaser').on('click', function () { location.href = $(this).find('.blog-post-teaser__link').attr('href'); });

        var onWindowResize = function () {
            var windowWidth = window.innerWidth || $(window).width();
            $('.columns').each(function () {
                var columns = $(this);
                var boxes = $('.blog-post-teaser .blog-post-teaser__content', columns);
                if (boxes.length > 1) { //only do something if more than 1 white box in the same grid
                    boxes.css('height', 'auto');
                    //do not set height on mobiles, there we always have 1 column only
                    if (windowWidth >= CONFIG.breakpoints.tabletPortraitMinWidth) {
                        var maxHeight = Math.max.apply(null, boxes.map(function () {
                            return $(this).height();
                        }).get());
                        boxes.height(maxHeight);
                    }
                }
            });
        };

        var timeout = null;
        $(window).on('resize', function () {
            if (timeout) clearTimeout(timeout);
            timeout = window.setTimeout(onWindowResize, 200);
        });

        onWindowResize();
    });
})(jQuery);