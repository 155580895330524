function social_media_popup(social_media, page_url, text) {
    var url;
    page_url = encodeURI(page_url);
    text = encodeURI(text);
    switch (social_media) {
        case "facebook": url = "https://www.facebook.com/sharer/sharer.php?u=" + page_url;
            break;
        case "twitter": url = "https://twitter.com/intent/tweet?text=" + text + "&url=" + page_url;
            break;
        case "linkedin": url = "https://www.linkedin.com/shareArticle?mini=true&url=" + page_url + "&title=" + text + "&summary=" + text;
            break;
    }
    popupWindow = window.open(url, 'popUpWindow', 'height=500,width=600,left=250,top=100,resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no,status=yes');
    return false;
}

(function ($) {
    $(function () {
        $('#scrollToTop').addClass('scrollToTop--higher');
    });
})(jQuery);