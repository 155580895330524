(function ($) {
    $(function () {
        var $overlay = $('.js-main-overlay');
        //var $mainNav = $('.js-main-nav');
        var $searchNavForm = $('.js-search-nav-form');
        var $body = $('body');

        $('.js-search-nav-toggle').on('click', function (ev) {
            ev.preventDefault();
            //$mainNav.toggle();
            $overlay.toggleClass('layout__main-overlay--active');
            $searchNavForm.toggleClass('search-nav__form--active');
            $body.toggleClass('search-nav--visible');
            $('.js-search-nav-toggle').toggleClass('search-nav__toggle--active');
            $('.js-search-nav-input').focus();
        });
    });
})(jQuery);
