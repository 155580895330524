(function ($) {
    $(function () {

        $('.slider').each(function () {
            var slider = $(this);
            var items = slider.find('.slider__item');
            if (items.length > 1) {
                var autoplay = !slider.data("editing");
                slider.slick({ arrows: false, dots: true, dotsClass: 'slider__pager', autoplay: autoplay, autoplaySpeed: 5000 });
            }
        });

    });
})(jQuery);
