(function ($) {    

    $(function () {
        $('#submit-ep').click(function (e) {
            e.preventDefault();

            var data = objectifyForm("#form-ep");
            data.ClientId = $('#client-id').val();

            $.ajax({
                type: "POST",
                url: "/migrolapi/crmtests/SaveEmailPermissions",
                data: data,
                dataType: 'json',
                cache: false,
                success: function () { updateClientData(); alert("success!"); },
                error: function (jqXHR, textStatus, errorThrown) { alert("error: " + jqXHR.responseText); }
            });

            return false;
        });

        $('#submit-kz').click(function (e) {
            e.preventDefault();

            var data = objectifyForm("#form-kz");
            data.ClientId = $('#client-id').val();

            $.ajax({
                type: "POST",
                url: "/migrolapi/crmtests/SaveClientAcceptance",
                data: data,
                dataType: 'json',
                cache: false,
                success: function () { updateClientData(); alert("success!"); },
                error: function (jqXHR, textStatus, errorThrown) { alert("error: " + jqXHR.responseText); }
            });

            return false;
        });

        $('#submit-mm').click(function (e) {
            e.preventDefault();

            var data = objectifyForm("#form-mm");
            data.ClientId = $('#client-id').val();

            $.ajax({
                type: "POST",
                url: "/migrolapi/crmtests/SaveMerkmale",
                data: data,
                dataType: 'json',
                cache: false,
                success: function () { updateClientData(); alert("success!"); },
                error: function (jqXHR, textStatus, errorThrown) { alert("error: " + jqXHR.responseText); }
            });

            return false;
        });

        $('#submit-comp').click(function (e) {
            e.preventDefault();

            var data = objectifyForm("#form-comp");
            data.ClientId = $('#client-id').val();

            $.ajax({
                type: "POST",
                url: "/migrolapi/crmtests/SaveCompetitionParticipation",
                data: data,
                dataType: 'json',
                cache: false,
                success: function () { updateClientData(); alert("success!"); },
                error: function (jqXHR, textStatus, errorThrown) { alert("error: " + jqXHR.responseText); }
            });

            return false;
        });

        $('#client-getdata').click(function (e) {
            e.preventDefault();
            updateClientData();
            return false;
        });
    });

    function updateClientData() {
        var data = {};
        data.ClientId = $('#client-id').val();

        $.ajax({
            type: "GET",
            url: "/migrolapi/crmtests/GetClientInfo",
            data: data,
            dataType: 'json',
            cache: false,
            success: function (result) { $("#client-data").html(result); },
            error: function (jqXHR, textStatus, errorThrown) { alert("error: " + jqXHR.responseText); }
        });
    }

    function objectifyForm(formSelector) {//serialize data function
        var formArray = $(formSelector).serializeArray();
        var returnArray = {};
        for (var i = 0; i < formArray.length; i++) {
            returnArray[formArray[i]['name']] = formArray[i]['value'];
        }
        return returnArray;
    }

})(jQuery);


