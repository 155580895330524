(function ($) {
    $(function () {
        var columnsMediumMinWidth = 393;
        var columnsLargeMinWidth = 595;//750;
        var columnsXLargeMinWidth = 900;

        var onWindowResize = function () {
            $('.columns__col').each(function () {
                var col = $(this);
                var colWidth = col.width();

                if (colWidth < columnsMediumMinWidth) {
                    col.addClass('col--small');
                    col.removeClass('col--medium');
                    col.removeClass('col--large');
                    col.removeClass('col--x-large');
                } else if (colWidth < columnsLargeMinWidth) {
                    col.removeClass('col--small');
                    col.addClass('col--medium');
                    col.removeClass('col--large');
                    col.removeClass('col--x-large');
                } else if (colWidth < columnsXLargeMinWidth) {
                    col.removeClass('col--small');
                    col.removeClass('col--medium');
                    col.addClass('col--large');
                    col.removeClass('col--x-large');
                } else {
                    col.removeClass('col--small');
                    col.removeClass('col--medium');
                    col.removeClass('col--large');
                    col.addClass('col--x-large');
                }
            });
        };

        var timeout = null;
        $(window).on('resize', function () {
            if (timeout) clearTimeout(timeout);
            timeout = window.setTimeout(onWindowResize, 200);
        });

        onWindowResize();
    });
})(jQuery);