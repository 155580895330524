(function ($) {
    $(function () {

        var onWindowResize = function () {
            var windowWidth = window.innerWidth || $(window).width();
            $('.columns').each(function () {
                var columns = $(this);
                var boxes = $('.teaser--box .teaser__content, .teaser--no-box-white .teaser__content, .teaser--no-box-black .teaser__content', columns);
                if (boxes.length > 1) { //only do something if more than 1 white box in the same grid
                    boxes.css('height', 'auto');
                    //do not set height on mobiles, there we always have 1 column only
                    if (windowWidth >= CONFIG.breakpoints.tabletPortraitMinWidth) {
                        var maxHeight = Math.max.apply(null, boxes.map(function () {
                            return $(this).height();
                        }).get());
                        boxes.height(maxHeight);
                    }
                }
            });
        };

        var timeout = null;
        $(window).on('resize', function () {
            if (timeout) clearTimeout(timeout);
            timeout = window.setTimeout(onWindowResize, 200);
        });

        onWindowResize();

        //make the whole teaser clickable
        if (!CONFIG.inExpEditor) {
            $('.teaser__link').on('click', function (ev) {
                trackTeaser($(this).closest('.teaser'), 'click');
                ev.stopPropagation();
            });
            $('.teaser').on('click', function () {
                if (!$(this).hasClass("teaser--no-link")) {
                    $(this).find('.teaser__link')[0].click();
                }
            });
        }

        $('.teaser').each(function () {
            var teaser = $(this);
            trackTeaser(teaser, 'view');
        });

        function trackTeaser(teaser, event) {
            var trackingEnabled = teaser.data('tracking-enabled');
            if (trackingEnabled) {
                var trackingObj = getTeaserTrackingData(teaser);
                trackPromotion(trackingObj, event); //from _trackingPromotion.js
            }
        }

        function getTeaserTrackingData(teaser) {
            return {
                promotion_id: teaser.data('tracking-id'), // Name or ID is required.
                promotion_name: teaser.data('tracking-name').replace(/'/g, "\\'"), // Name des Banners
                creative_name: teaser.data('tracking-creative').replace(/'/g, "\\'"),// Verallgemeinerung Inhalt (Aktion, Gutschein, Themenpromotion, Wettbewerb etc.)
                location_id: 'teaser', // Art der Ausspielung, Bannergrösse optional (Teaser, Slider,MainTeaser etc.)
                creative_slot: '1/1'
            }
            //note: not used anymore in the new 2021 version:
            //trackingObj['bereich'] = heroItem.data('tracking-area').replace(/'/g, "\\'");
            //trackingObj['rubrik'] = heroItem.data('tracking-category').replace(/'/g, "\\'");
        }
    });

})(jQuery);
