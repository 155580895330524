(function ($) {
    $(function () {
        $('.wl-teaser__items').each(function (index, item) {
            var $item = $(item);
            $item.slick({
                arrows: true,
                prevArrow: '<button type="button" class="wl-teaser__button wl-teaser__button--previous"><i class="fas fa-chevron-left"></i></button>',
                nextArrow: '<button type="button" class="wl-teaser__button wl-teaser__button--next"><i class="fas fa-chevron-right"></i></button>',
                dots: true,
                dotsClass: 'wl-teaser__pager',
                mobileFirst: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                accessibility: false,
                infinite: false,
                respondTo: 'slider',
                responsive: [
                    {
                        breakpoint: 700,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 400,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        });
    });
})(jQuery);
