(function ($) {
    $(function () {

        $('.js-ts-finder-teaser__autocomplete').on('focus', function () {
            var input = this;
            var $input = $(this);

            if ($input.data('autocomplete-initialized') == 'true') {
                //prevent double-initialization
                return;
            }

            $input.data('autocomplete-initialized', 'true');
            window.loadGoogleMapsApi(function () {
                var autocomplete = new google.maps.places.Autocomplete(input);
                autocomplete.setComponentRestrictions({ 'country': 'CH' });
                autocomplete.setFields(['place_id']);
            });
        });



        $('body').on('submit', '.ts-finder-teaser__form', function (e) {
            formOnSubmit($(this));
        });

        function formOnSubmit(form) {
            var filter = $('input[name=filter]:checked', form).val();
            var filterTrackingName = '';
            switch (filter) {
                case 'mc':
                    filterTrackingName = 'Migrolcard';
                    break;
                case 'cw':
                    filterTrackingName = 'Car Wash';
                    break;
                case 'ws':
                    filterTrackingName = 'Auto Service';
                    break;
                case 'ts':
                    filterTrackingName = 'Tankstellen';
                    break;
                case 'fc':
                    filterTrackingName = 'Fast Charging';
                    break;
                default:
                    break;
            }

            // tracking:
            if (!CONFIG.inExpEditor) {
                trackTeaser(form.closest('.ts-finder-teaser'), 'click', filterTrackingName);
            }
        }

        function trackTeaser(teaser, event, category) {
            var trackingObj = getTeaserTrackingData(teaser, category);
            trackPromotion(trackingObj, event); //from _trackingPromotion.js
        }

        function getTeaserTrackingData(teaser, category) {
            return {
                promotion_id: teaser.data('tracking-id'), // Name or ID is required.
                promotion_name: category.replace(/'/g, "\\'"), // Name des Banners
                creative_name: teaser.data('tracking-creative').replace(/'/g, "\\'"),// Verallgemeinerung Inhalt (Aktion, Gutschein, Themenpromotion, Wettbewerb etc.)
                location_id: 'ts-suche', // Art der Ausspielung, Bannergrösse optional (Teaser, Slider,MainTeaser etc.)
                creative_slot: '1/1'
            }
            //note: not used anymore in the new 2021 version:
            //trackingObj['bereich'] = teaser.data('tracking-area').replace(/'/g, "\\'");
            //trackingObj['rubrik'] = category.replace(/'/g, "\\'");
        }
    });
})(jQuery);