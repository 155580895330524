// global function needed because it's called form the view
function setUpPriceIndexUI($, containerElement, dataWeek, dataMonth, dataYear, dataYearsCombined, optionsWeek, optionsMonth, optionsYear, optionsYearsCombined) {

    $('.price-index__tab').on('click', function (ev) {
        ev.preventDefault();
        var $this = $(this);
        $this.siblings().removeClass('price-index__tab--active');
        $this.addClass('price-index__tab--active');
        $this.closest('.price-index').find('.price-index__tab-content--active').removeClass('price-index__tab-content--active');
        var contentWrapper = $this.closest('.price-index').find('.price-index__tab-content').eq($this.index());
        contentWrapper.addClass('price-index__tab-content--active');
        showChart(contentWrapper.find('.price-index__chart').data('charttype'));
    });
    
    function drawWeek() {
        if (dataWeek) {
            var chart = new google.visualization.LineChart($('.price-index__chart--week', containerElement)[0]);
            chart.draw(dataWeek, optionsWeek);
        }
    }
    function drawMonth() {
        if (dataMonth) {
            var chart = new google.visualization.LineChart($('.price-index__chart--month', containerElement)[0]);
            google.visualization.events.addListener(chart, 'select', function () { highlightLine(chart, dataMonth, optionsMonth); });
            chart.draw(dataMonth, optionsMonth);            
        }
    }
    function drawYear() {
        if (dataYear) {
            var chart = new google.visualization.LineChart($('.price-index__chart--year', containerElement)[0]);
            google.visualization.events.addListener(chart, 'select', function () { highlightLine(chart, dataYear, optionsYear); });
            chart.draw(dataYear, optionsYear);
        }
    }
    function drawYearsCombined() {
        if (dataYearsCombined) {
            var chart = new google.visualization.LineChart($('.price-index__chart--years-combined', containerElement)[0]);
            chart.draw(dataYearsCombined, optionsYearsCombined);
        }
    }

    function redrawAll() {
        if ($('.price-index__chart--week', containerElement).is(":visible")) {
            drawWeek();
        }
        if ($('.price-index__chart--month', containerElement).is(":visible")) {
            dataMonth();
        }
        if ($('.price-index__chart--year', containerElement).is(":visible")) {
            drawYear();
        }
        if ($('.price-index__chart--years-combined', containerElement).is(":visible")) {
            drawYearsCombined();
        }
    }

    function showChart(type) {
        switch (type) {
            case 'week':
                drawWeek();
                break;
            case 'month':
                drawMonth();
                break;
            case 'year':
                drawYear();
                break;
            case 'yearscombined':
                drawYearsCombined();
                break;
        }
    }

    function highlightLine(chart, data, options) {
        var lineWidthThin = 1;
        var lineWidthThick = 3;
        var selectedItem = chart.getSelection()[0];
        for (var i in options.series) {
            options.series[i].lineWidth = lineWidthThin;
        }
        options.series[selectedItem.column - 1].lineWidth = lineWidthThick;
        chart.draw(data, options);
    }

    //create trigger to resizeEnd event
    $(window).resize(function () {
        if (this.resizeTO) clearTimeout(this.resizeTO);
        this.resizeTO = setTimeout(function () {
            redrawAll();
        }, 500);
    });

    drawYear();
}