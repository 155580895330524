function trackPromotion(trackingObj, event) {
    var eventName = "";
    switch (event) {
        case 'click':
            eventName = "select_promotion";
            break;
        default: //'view'
            eventName = "view_promotion";
    }
    var dlObject = {
        event: eventName,
        ecommerce: {
            items: [trackingObj]
        }
    };
    dataLayer.push(dlObject);
}