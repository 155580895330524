(function ($) {
    $(function () {
        $('.timeline').each(function () {
            var timeline = $(this);
            var currentSlideIndex = 0;

            $('.timeline__controls-button-prev', timeline).on('click', function (ev) {
                setSlide(currentSlideIndex - 1, timeline);
            });
            $('.timeline__controls-button-next', timeline).on('click', function (ev) {
                setSlide(currentSlideIndex + 1, timeline);
            });

            function setSlide(index, timeline) {
                if (index < 0)
                    return;
                var slideCount = timeline.data('slidecount');
                if (index > slideCount - 1)
                    return;
                var progress = (slideCount == 1 ? 0 : index / (slideCount - 1)) * 100;

                //set curretn index value
                currentSlideIndex = index;

                //set correct slide content and image:
                timeline.find('.timeline__content-slide').hide();
                timeline.find('.timeline__content-slide[data-slideindex="' + index + '"]').show();
                timeline.find('.timeline__image').hide();
                timeline.find('.timeline__image[data-slideindex="' + index + '"]').show();

                //set current year:
                var slideYear = timeline.find('.timeline__content-slide[data-slideindex="' + index + '"]').first().data('slideyear');
                timeline.find('.timeline__controls-slider-bar-position-currentyear').html(slideYear);

                //set progress
                timeline.find('.timeline__controls-slider-bar-inner').width(progress + '%');
                timeline.find('.timeline__controls-slider-bar-position').css('left', progress + '%');


                //for the first and last slides, enable/disable prev/next buttons
                if (index == 0) {
                    timeline.find('.timeline__controls-button-prev')
                        .addClass("timeline__controls-button--disabled")
                        .prop('disabled', true);
                } else {
                    timeline.find('.timeline__controls-button-prev')
                        .removeClass("timeline__controls-button--disabled")
                        .prop('disabled', false);
                }
                if (index == slideCount - 1) {
                    timeline.find('.timeline__controls-button-next')
                        .addClass("timeline__controls-button--disabled")
                        .prop('disabled', true);
                } else {
                    timeline.find('.timeline__controls-button-next')
                        .removeClass("timeline__controls-button--disabled")
                        .prop('disabled', false);
                }
            }

            setSlide(0, timeline);
        });
    });
})(jQuery);