(function ($) {
    var migrosLoginEndpoint = CONFIG.mLoginEndpoint;
    updateLoginButtonUi();

    function updateLoginButtonUi() {
        var language = $('html').attr('lang');
        var loginButton = $('.mlogin__button');
        var returnUri = encodeURIComponent(window.location.href); //encodeURIComponent encodes also ampersands and quers string elements : https://stackoverflow.com/questions/11294107/how-can-i-send-the-ampersand-character-via-ajax

        if (mLoginData.isLoggedInOnRp === true) { // if user is logged in

            var request = getUserData(function (data) {
                //console.log("getUserData:", data);
                $('.mlogin__user')
                    .show()
                    .attr('href', `${migrosLoginEndpoint}/inlink/migrol/${language}/welcome?return_to=${returnUri}`)
                    .html(data.firstname + " " + data.lastname)
                    .show();
            });
            $.ajax(request);

            loginButton.text(CONFIG.mLoginTextLogout);
            loginButton.attr('href', '/auth/logout?returnUrl=' + returnUri);
        }
        else { // if user not logged in
            $('.mlogin__user').hide();
            loginButton.text(CONFIG.mLoginTextLogin);
            loginButton.attr('href', '/auth/login?returnUrl=' + returnUri);
        }
    }

    function getUserData(successCallback) {
        var request = {
            url: '/auth/user-data',
            dataType: 'json',
            crossDomain: true,
            cache: false,
            xhrFields: { withCredentials: true },
            success: successCallback,
            error: function (jqXHR, textStatus, errorThrown) { }
        };
        // earlier IE versions do not support CORS with credentials, use jsonp as fallback
        if (typeof XDomainRequest !== "undefined") {
            request.dataType = "jsonp";
            request.timeout = 1000; // no real error handling for JSONP, use timeout to trigger error callback
        }
        return request;
    }
})(jQuery);


