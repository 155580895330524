(function ($) {
    $(function () {
        var activeNav = [];
        var breadcrumbItems = [];
        var backButton = $('.js-main-nav__back-button');
        var breadcrumb = $('.js-main-nav__breadcrumbs');
        var $overlay = $('.js-main-overlay');
        var $mainNav = $('.js-main-nav');
        var $navBar = $('.layout__header-navbar');
        var $body = $('body');
        var $mainNavScrollContainer = $('.main-nav__ul--level-1').first();

        $('.js-main-nav--drilldown').on('click', function (ev) {
            ev.preventDefault();
            var $this = $(this);
            var active = $this.siblings('.main-nav__ul');
            openNav(active, $this.text());
            updateBreadcrumbs();
        });

        backButton.on('click', function (ev) {
            ev.preventDefault();
            closeCurrentNav();
            updateBreadcrumbs();
        });

        $('.js-hamburger').on('click', function (ev) {
            ev.preventDefault();
            $(this).toggleClass('is-active');
            $overlay.toggleClass('main-overlay--force');
            $body.toggleClass('mobile-nav-open');
            $mainNav.show(0).toggleClass('main-nav--active');
        });

        //call this function, when a breadcrumb item is clicked and jump back the necessary amount of levels
        breadcrumb.on('click', '.main-nav__breadcrumbs-item', function (ev) {
            ev.preventDefault();
            var back = parseInt($(this).data('back'));
            if (!isNaN(back)) {
                for (var i = 0; i < back; i++) {
                    closeCurrentNav();
                }
                updateBreadcrumbs();
            }
        });

        //set main-nav to position:fixed when at the top of the screen
        if (!$body.hasClass('in-experience-editor')) {
            var mainNavOffsetTop = $navBar.offset().top;
            $(window)
                .on('scroll', function () {
                    var scrollPos = $(this).scrollTop();
                    if (scrollPos >= mainNavOffsetTop) {
                        $body.addClass('layout__header-navbar--fixed-body-offset');
                        $navBar.addClass('layout__header-navbar--fixed');
                    }
                    else {
                        $body.removeClass('layout__header-navbar--fixed-body-offset');
                        $navBar.removeClass('layout__header-navbar--fixed');
                    }
                })
                .on('resize orientationchange', function () {
                    $body.removeClass('layout__header-navbar--fixed-body-offset');
                    $navBar.removeClass('layout__header-navbar--fixed');
                    mainNavOffsetTop = $navBar.offset().top;
                    $(this).trigger('scroll');
                })
                .trigger('scroll'); //trigger initial scroll
        }

        function updateBreadcrumbs() {
            if (breadcrumbItems.length > 0) {
                breadcrumb.addClass('main-nav__breadcrumbs--active');
                backButton.addClass('main-nav__back-button--active');

                var html = '<div><button class="main-nav__breadcrumbs-item" data-back="' + breadcrumbItems.length + '">Home</button>';
                for (var i = 0; i < breadcrumbItems.length; i++) {
                    html += ' <i class="fas fa-chevron-right"></i> ';
                    if (i < breadcrumbItems.length - 1) {
                        //not last item
                        html += '<button class="main-nav__breadcrumbs-item" data-back="' + (breadcrumbItems.length - i - 1) + '">' + breadcrumbItems[i] + '</button>';
                    } else {
                        //last item
                        html += '<span class="main-nav__breadcrumbs-item main-nav__breadcrumbs-item--current">' + breadcrumbItems[i] + '</span>';
                    }
                }
                html += '</div>';
                breadcrumb.html(html);
            } else {
                breadcrumb.removeClass('main-nav__breadcrumbs--active');
                backButton.removeClass('main-nav__back-button--active');
            }
        }

        function openNav(navUl, title) {
            navUl.addClass('main-nav__ul--active');
            activeNav.push(navUl);
            breadcrumbItems.push(title);

            var height = navUl.outerHeight();
            $mainNavScrollContainer.css('height', 'max(calc(100vh - 6rem), ' + height + 'px)');
        }

        function closeCurrentNav() {
            breadcrumbItems.pop();
            var active = activeNav.pop();
            active.removeClass('main-nav__ul--active');

            if (activeNav.length > 0) {
                var height = activeNav[activeNav.length - 1].outerHeight();
                $mainNavScrollContainer.css('height', 'max(calc(100vh - 6rem), ' + height + 'px)');
            } else {
                $mainNavScrollContainer.css('height', 'auto');
            }
        }

        if (is_touch_device()) {
            //this is for touch devices:
            $('.main-nav__link--level-1.main-nav__link--desktop')
                .on('click', function () {
                    var li = $(this).closest('.main-nav__li--level-1');
                    if (!li.hasClass('is-open')) {
                        //we check, if the parent level-1 LI has the .is-open class.
                        //if it is not open yet, prevent the actual navigation and instead only open the slide-down nav

                        //first remove is-open from all other elements
                        $('.main-nav__li--level-1.is-open').not(li).removeClass('is-open');

                        //add is-open to current one and show the semitransparent overlay over the content
                        li.addClass('is-open');
                        $overlay.addClass('layout__main-overlay--active');

                        //prevent following the link
                        return false;
                    }
                });

            $overlay.on('click', function () {
                var openNav = $('.main-nav__li--level-1.is-open');
                if (openNav.length > 0) {
                    openNav.removeClass('is-open');
                    $overlay.removeClass('layout__main-overlay--active');
                }
            });
        } else {
            //opening/closing of main nav on non-touch devices
            $('.main-nav__li--level-1')
                .on('mouseover', function () {
                    $(this).addClass('is-open');
                    $overlay.addClass('layout__main-overlay--active');
                })
                .on('mouseout', function () {
                    $(this).removeClass('is-open');
                    $overlay.removeClass('layout__main-overlay--active');
                });
        }
    });

    function is_touch_device() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function (query) {
            return window.matchMedia(query).matches;
        };

        if ('ontouchstart' in window || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }
})(jQuery);
