(function ($) {
    $(function () {
        var $window = $(window);
        var $scrollToTop = $('#scrollToTop');

        //scroll to top button
        $scrollToTop.on('click', function () {
            $('html,body').animate({ scrollTop: 0 }, 'slow');
        });

        $window.on('scroll orientationchange', function () {
            if ($window.scrollTop() > 500)
                $scrollToTop.addClass('scrollToTop--visible');
            else
                $scrollToTop.removeClass('scrollToTop--visible');
        });

        //autocomplete for search box
        $('.js-search-nav-input, .js-searchbox-input, .js-searchresults-input').each(function () {
            var $this = jQuery(this);
            $this.autocomplete({
                appendTo: $this.closest('form'),
                serviceUrl: '/' + CONFIG.language + '/migrolapi/search',
                paramName: 'query',
                dataType: 'json',
                maxHeight: 800,
                deferRequestBy: 200,
                beforeRender: function (container, suggestions) {
                    container.prepend('<div class="autocomplete-intro">' + $this.data('top-results-text') + '</div>');
                },
                formatResult: function (suggestion, currentValue) {
                    return '<div class="autocomplete-title">' + suggestion.value + '</div><div class="autocomplete-highlight">' + suggestion.data.highlights + '</div>';
                },
                onSelect: function (suggestion) {
                    location.href = suggestion.data.url;
                }
            });
        });

        $('.js-goal-tracking-link').on('click', function () {
            var goalId = $(this).data('tracking-goal-id');
            if (goalId && goalId !== '') {
                $.post('/migrolapi/tracking/TriggerGoal', { GoalId: goalId });
            }
        });

        //add noopener to all external links
        $('a[target="_blank"]').attr('rel', 'noopener');
    });
})(jQuery);

var lazyLoadInstance = new LazyLoad({
    elements_selector: "[data-src],[data-bg]"
});

//initialize tippy tooltips
tippy('.js-tooltip', {
    appendTo: function () { return document.body; },
    zIndex: 500
});